// https://stackoverflow.com/a/28085767/351758
import $ from 'jquery'

$.fn.enterAsTab = function () {
    $(this).find('input, select, textarea, button').on("keydown", function (event) {
        const type = $(this).attr("type");
        if (type === "submit") {
            return true;
        }

        if (event.keyCode === 13) {
            const inputs = $(this).parents("form").eq(0).find(":input:visible:not(:disabled):not([readonly])");
            let idx = inputs.index(this);
            if (idx === inputs.length - 1) {
                idx = -1;
            } else {
                inputs[idx + 1].focus(); // handles submit buttons
            }
            try {
                inputs[idx + 1].select();
            } catch (err) {

            }
            return false;
        }
    });
    return this;
};
