import $ from 'jquery'

$.fn.tippuebersicht = function () {
    const ranking = $('#ranking');
    const wrapper = $('body');
    const columnWidth = [];
    let widthGap;
    let bonusHideable = false;
    let siegeHideable = false;
    const orderedEreignisIndexe = [];
    let firstEreignis;
    let lastEreignis;
    let width = $(window).width();

    function resizeWindow() {
        let newWidth = $(window).width();
        if (width !== newWidth) {
            ranking.find('.positionsdifferenz').show();
            ranking.find('.ereignis').show();
            ranking.find('.bonus').show();
            ranking.find('.siege').show();
            setBonusUndSiegeHideable();
            initOrderedEreignisIndexe();
            resizeRanking();
            width = newWidth;
        }
    }

    function resizeRanking() {
        resizeMitgliedName();
        initColumnWidth();
        hideColumns();
    }

    function resizeMitgliedName() {
        widthGap = $(ranking).getPixelNotInViewport(wrapper);
        if ($(window).width() < 1024) {
            if (widthGap === 0) {
                ranking.find('.mg_name').removeClass('small');
                ranking.find('.mg_class').removeClass('small');
                ranking.find('.mg_name').addClass('big');
                ranking.find('.mg_class').addClass('big');
                widthGap = $(ranking).getPixelNotInViewport(wrapper);
            }
            if (widthGap > 0) {
                ranking.find('.mg_name').removeClass('big');
                ranking.find('.mg_class').removeClass('big');
                widthGap = $(ranking).getPixelNotInViewport(wrapper);
            }
            if (widthGap > 0) {
                ranking.find('.mg_name').addClass('small');
                ranking.find('.mg_class').addClass('small');
                widthGap = $(ranking).getPixelNotInViewport(wrapper);
            }
        }
    }

    function hideColumns() {
        $.each(getColumnClassesToHide(), function (index, value) {
            hideColumn(value);
        })
        updatePrevNextLinks();
        checkFragenHeader();
    }

    function getColumnClassesToHide() {
        let hiddenWidth = 0;
        const columnClasses = [];
        if (hiddenWidth < widthGap && bonusHideable) {
            columnClasses.push('bonus');
            hiddenWidth += columnWidth['bonus'];
        }
        if (hiddenWidth < widthGap && siegeHideable) {
            columnClasses.push('siege');
            hiddenWidth += columnWidth['siege'];
        }
        $.each(orderedEreignisIndexe, function (index, value) {
            if (hiddenWidth < widthGap) {
                columnClasses.push('ereignis' + value);
                hiddenWidth += columnWidth['ereignis' + value];
            } else {
                return columnClasses;
            }
        })
        if (hiddenWidth < widthGap) {
            columnClasses.push('positionsdifferenz');
        }
        return columnClasses;
    }

    function hideColumn(columnClass) {
        ranking.find('.' + columnClass).hide();
    }

    function showColumn(columnClass) {
        ranking.find('.' + columnClass).show();
    }

    function checkFragenHeader() {
        let frageId = 0;
        $('th.ereignis:visible').each(function (index, ereignis) {
            const tempFrageId = $(ereignis).data('frageid');
            if (tempFrageId !== undefined) {
                if (tempFrageId !== frageId) {
                    $(ereignis).find('.kurzfrage').show();
                    frageId = tempFrageId;
                } else {
                    $(ereignis).find('.kurzfrage').hide();
                }
            }
        });
    }

    function updatePrevNextLinks() {
        const firstVisible = firstEreignis.is(':visible');
        const lastVisible = lastEreignis.is(':visible');
        const rprev = $('.rprev');
        const rnext = $('.rnext');
        if (firstVisible && lastVisible) {
            rprev.hide();
            rnext.hide();
        } else {
            rprev.show();
            rnext.show();
            if (firstVisible) {
                rprev.addClass('disabled');
            } else {
                rprev.removeClass('disabled');
            }
            if (lastVisible) {
                rnext.addClass('disabled');
            } else {
                rnext.removeClass('disabled');
            }
        }
    }

    function handlePrev() {
        let icon = $('.rprev');
        icon.addClass("clicked");
        let firstVisibleEreignisIndex = $('tr.headerErgebnis th.ereignis').filter(':visible').first().data('index');
        if (firstVisibleEreignisIndex > 0) {
            firstVisibleEreignisIndex--;
            showColumn('ereignis' + firstVisibleEreignisIndex);
            let lastVisibleEreignisIndex = $('tr.headerErgebnis th.ereignis').filter(':visible').last().data('index');
            do {
                hideColumn('ereignis' + lastVisibleEreignisIndex);
                lastVisibleEreignisIndex--;
            }
            while (ranking.getPixelNotInViewport(wrapper) > 0 && lastVisibleEreignisIndex > firstVisibleEreignisIndex);
            updatePrevNextLinks();
            const selectedEreignisIndex = ranking.data('selectedereignisindex');
            ranking.data('selectedereignisindex', selectedEreignisIndex - 1);
        }
        checkFragenHeader();
        setTimeout(() => icon.removeClass("clicked"), 180);
    }

    function handleNext() {
        let icon = $('.rnext');
        icon.addClass("clicked");
        let lastVisibleEreignisIndex = $('tr.headerErgebnis th.ereignis').filter(':visible').last().data('index');
        if (lastVisibleEreignisIndex < lastEreignis.data('index')) {
            lastVisibleEreignisIndex++;
            showColumn('ereignis' + lastVisibleEreignisIndex);
            let firstVisibleEreignisIndex = $('tr.headerErgebnis th.ereignis').filter(':visible').first().data('index');
            do {
                hideColumn('ereignis' + firstVisibleEreignisIndex);
                firstVisibleEreignisIndex++;
            }
            while (ranking.getPixelNotInViewport(wrapper) > 0 && firstVisibleEreignisIndex < lastVisibleEreignisIndex);
            updatePrevNextLinks();
            const selectedEreignisIndex = ranking.data('selectedereignisindex');
            ranking.data('selectedereignisindex', selectedEreignisIndex + 1);
        }
        checkFragenHeader();
        setTimeout(() => icon.removeClass("clicked"), 180);
    }



    function bindEvents() {
        $('.rprev').click(handlePrev);
        $('.rnext').click(handleNext);
        window.bindArrowKeys(handlePrev, handleNext)
        window.bindSwipe(ranking[0], handleNext, handlePrev);
        $(window).resize(resizeWindow);
    }

    function init() {
        firstEreignis = $('th.ereignis').first();
        lastEreignis = $('th.ereignis').last();
        setBonusUndSiegeHideable();
        widthGap = $(ranking).getPixelNotInViewport(wrapper);
        initColumnWidth();
        initOrderedEreignisIndexe();
    }

    function initOrderedEreignisIndexe() {
        let indexPrev;
        let indexNext;
        const selectedEreignisIndex = ranking.data('selectedereignisindex');
        const selectedEreignis = ranking.find('tr.headerErgebnis th.ereignis' + selectedEreignisIndex);
        const maxEreignisIndex = lastEreignis.data('index');
        if (selectedEreignis.data('live')) {
            // Bei Liveereignissen werden zunächst alle folgenden
            // Liveereignisse angezeigt
            const maxLiveIndex = $('tr.headerErgebnis th.ereignis[data-live="true"]').last().data('index');
            indexNext = selectedEreignisIndex + 1;
            while (indexNext <= maxLiveIndex) {
                orderedEreignisIndexe.push(indexNext);
                indexNext++;
            }
            // Danach werden alle vorherigen Ereignisse
            indexPrev = selectedEreignisIndex - 1;
            while (indexPrev >= 0) {
                orderedEreignisIndexe.push(indexPrev);
                indexPrev--;
            }
            // Und schließlich werden noch die späteren Ereignisse
            // angezeigt, die
            // nicht live sind
            while (indexNext <= maxEreignisIndex) {
                orderedEreignisIndexe.push(indexNext);
                indexNext++;
            }
        } else {
            // Bei offenen oder beendeten Ereignissen muss nach Spiel/Frage
            // unterschieden werden.
            if (selectedEreignis.data('spiel')) {
                // Bei einem Spiel werden zunächst alle früheren Ereignisse
                // angezeigt
                indexPrev = selectedEreignisIndex - 1;
                while (indexPrev >= 0) {
                    orderedEreignisIndexe.push(indexPrev);
                    indexPrev--;
                }

                // Und danach dann alle späteren
                indexNext = selectedEreignisIndex + 1;
                while (indexNext <= maxEreignisIndex) {
                    orderedEreignisIndexe.push(indexNext);
                    indexNext++;
                }
            } else {
                // Bei einer Frage kann es mehrere Antwortfelder geben,
                // daher werden zunächst alle folgenden Antwortfelder der
                // ausgewählten Frage angezeigt
                let checkAntwortfelder = true;
                const frageid = selectedEreignis.data('frageid');
                indexNext = selectedEreignisIndex + 1;
                while (indexNext <= maxEreignisIndex && checkAntwortfelder) {
                    const ereignis = $('tr.headerErgebnis th.ereignis' + indexNext);
                    if (ereignis.data('frageid') === frageid) {
                        orderedEreignisIndexe.push(indexNext);
                        indexNext++;
                    } else {
                        checkAntwortfelder = false;
                    }
                }
                // Danach werden alle früheren Ereignisse angezeigt
                indexPrev = selectedEreignisIndex - 1;
                while (indexPrev >= 0) {
                    orderedEreignisIndexe.push(indexPrev);
                    indexPrev--;
                }
                // Und schliesslich wieder die späteren
                while (indexNext <= maxEreignisIndex) {
                    orderedEreignisIndexe.push(indexNext);
                    indexNext++;
                }
            }
        }
        // Da ausgeblendet wird, muss die Reihenfolge der Einträge noch
        // umgedreht werden
        orderedEreignisIndexe.reverse();
    }

    function setBonusUndSiegeHideable() {
        // Bonuspunkte und Spieltagssiege werden nur auf kleinen
        // Dipslays ausgeblendet.
        if ($(window).width() < 1024) {
            // Wenn nach einer der Spalten sortiert
            // wurde, wird diese auch auf kleinem Display nicht
            // ausgeblendet.
            const bonus = ranking.find('th.bonus');
            if (bonus.length && !bonus.hasClass('sort')) {
                // In der Bounsübersicht (BÜ = keine Spieltagspunkte
                // vorhanden) werden die Bonuspunkte nicht ausgeblendet
                const spieltagspunkte = ranking.find('th.spieltagspunkte');
                if (spieltagspunkte.length) {
                    bonusHideable = true;
                }
            }
            const siege = ranking.find('th.siege');
            if (siege.length && !siege.hasClass('sort')) {
                siegeHideable = true;
            }
        }
    }

    function initColumnWidth() {
        ranking.find('th.ereignis').each(function () {
            const clazz = 'ereignis' + $(this).data('index');
            columnWidth[clazz] = $(this).get(0).getBoundingClientRect().right - $(this).get(0).getBoundingClientRect().left;
        });
        if (bonusHideable) {
            const bonus = ranking.find('th.bonus');
            columnWidth['bonus'] = bonus.get(0).getBoundingClientRect().right - bonus.get(0).getBoundingClientRect().left;
        }
        if (siegeHideable) {
            const siege = ranking.find('th.siege');
            columnWidth['siege'] = siege.get(0).getBoundingClientRect().right - siege.get(0).getBoundingClientRect().left;
        }
    }

    return this.each(function () {
        init();
        bindEvents();
        resizeRanking();
    });
}
