import $ from 'jquery'

$(function () {
    const scrollTop = $('div#kicktipp-scrollTop').html();
    $(window).scrollTop(scrollTop);
});

function reload(e) {
    e.preventDefault();
    $('#kicktipp-reload a span').addClass("loading");
    let url = window.location.href;
    url = url.split('#')[0];
    url = appendScrollTop(url);
    url = appendEreignisIndex(url);
    navigate(url);
    return false;
}

function appendScrollTop(href) {
    if ($('div#kicktipp-scrollTop').length > 0) {
        const scrollTop = $(window).scrollTop();
        return appendParameter(href, 'scrollTop', scrollTop);
    }
    return href;
}

function appendEreignisIndex(href) {
    const ereignisIndex = $('#ranking').data('selectedereignisindex');
    if (ereignisIndex >= 0) {
        return appendParameter(href, 'ereignisIndex', ereignisIndex);
    }
    return href;
}

function appendParameter(href, parameterName, parameterValue) {
    if (href.indexOf('?') === -1) {
        href = href + '\u003F' + parameterName + '=' + parameterValue;
    } else {
        if (href.indexOf('?') + 1 === href.length) {
            href = href + parameterName + '=' + parameterValue;
        } else {
            if (href.indexOf(parameterName) === -1) {
                href = href + '\u0026' + parameterName + '=' + parameterValue;
            } else {
                const replace = '(' + parameterName + '=)[0-9]+';
                const regex = new RegExp(replace, "g");
                href = href.replace(regex, parameterName + '=' + parameterValue);
            }
        }
    }
    return href;
}

function isF5(e) {
    return e.which === 116;
}

function isCtrlR(e) {
    return e.ctrlKey && e.which === 82;
}

function triggerReloadKeys(e) {
    if (isF5(e) || isCtrlR(e)) {
        // click geht nicht: $('#kicktipp-reload a').click();
        reload(e);
        return false;
    }
}

$(document).bind("keydown", triggerReloadKeys);
$('#kicktipp-reload a').click(reload);
$('#reload a').click(reload);
