import $ from 'jquery'

// erst wenn alles gerendert ist, damit die Höhe zuverlässig ermittelt werden kann.
$(window).on("load", function () {
    if (integration_inIFrame()) {
        window.addEventListener('message', integration_kicktippInbox, false);
        integration_rewriteTargets();
        integration_bodyClickHandler();
        integration_overwrite_fetchjson();
        window.heightChanged = integration_postHeight;
        window.navigate = function (href) {
            parent.postMessage({
                event: 'fetch',
                url: href
            }, '*');
        }
        integration_postHeight();
        integration_postScroll();
    }
});

let integration_json_handler = () => {
}

function integration_overwrite_fetchjson() {
    window.fetchjson = function (url, options = {}) {
        parent.postMessage({
            event: 'fetchjson',
            url,
            options
        }, '*');
        return new Promise((resolve, reject) => {
            integration_json_handler = (json) => {
                resolve(json)
            }
        });
    }
}

function integration_bodyClickHandler() {
    $("body").click(function () {
        parent.postMessage({
            event: 'click',
        }, '*');
        integration_postHeight()
        return true;
    });
}

function integration_rewriteTargets() {
    // Wir gehen von folgenden Bedingungen aus:
    // Links innerhalb der Tipprunde sind releativ oder starten mit /kurzname
    $(".clickable").each(function () {
        const clickable = $(this);
        let rewrittenUrl = new URL(window.integrationsUrl)
        rewrittenUrl.searchParams.set("br_p", clickable.attr("data-url"));
        clickable.attr("data-url", rewrittenUrl.toString());
        clickable.click(integration_clickhandler);
    });
    $("a").each(function () {
        const a = $(this);
        const href = a.attr("href");
        if (href && !href.startsWith("javascript:")) {
            // Link startet mit kurzname/spielleiter
            if (href.indexOf(`/${window.kurzname}/spielleiter`) === 0) {
                a.attr("target", "_top");
                return;
            }
            // Link ist absolut
            if (href.match(/^https?:\/\//)) {
                if (a.attr("target") !== "_blank") {
                    a.attr("target", "_top");
                }
                return;
            }
            // beginnt mit / aber nicht /kurzname
            if (href.indexOf("/") === 0 && href.indexOf(`/${window.kurzname}/`) !== 0) {
                a.attr("target", "_top");
                return;
            }
            // alle anderen Links sind relativ zur Tipprunde.
            let rewrittenUrl = new URL(window.integrationsUrl)
            rewrittenUrl.searchParams.set("br_p", href);
            a.attr("href", rewrittenUrl.toString());
            a.click(integration_clickhandler);
        }
    });

    $("form").submit(function (event) {
        const form = $("form");
        const method = (form.attr('method') || 'GET').toUpperCase();
        const options = {
            method,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
        let url = form.attr('action');
        if (method === 'POST') {
            options.body = form.serialize()
        } else {
            url += "?" + form.serialize()
        }
        parent.postMessage({event: 'fetch', url, options}, '*');
        return false;
    });
}

function integration_clickhandler(event) {
    let href = event.currentTarget.getAttribute("href");
    if (!href) {
        href = event.currentTarget.getAttribute("data-url")
    }
    let linkUrl = new URL(href);
    let kicktippUrl = linkUrl.searchParams.get("br_p");
    parent.postMessage({
        event: 'fetch',
        url: kicktippUrl
    }, '*');
    return false;
}

function integration_postScroll() {
    parent.postMessage({
        event: 'scroll'
    }, '*');
}

function integration_postHeight() {
    parent.postMessage({
        event: 'height',
        height: $('#wbwrapper').height()
    }, '*');
}

function integration_inIFrame() {
    try {
        if (window.Cypress) {
            return window.parent !== window.top;
        }
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function integration_kicktippInbox(event) {
    const message = event.data;
    if (message.event === "resultjson") {
        integration_json_handler(message.data);
    }
    if (message.event === "darkmode") {
        if (message.status === 'on') {
            activateDarkmode()
        }
        if (message.status === 'off') {
            deactivateDarkmode();
        }
    }
}
