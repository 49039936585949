import 'vite/modulepreload-polyfill'

window.registerPush = function () {
    window.onAppResume.next = () => window.location.reload();
    if (window.webkit) {
        window.webkit.messageHandlers.registerPush.postMessage('')
    } else {
        Android.registerPush();
    }
}

window.openSettings = function () {
    window.onAppResume.next = () => window.location.reload();
    if (window.webkit) {
        window.webkit.messageHandlers.openSettings.postMessage("");
    } else {
        Android.showAppSettings();
    }
}