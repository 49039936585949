import Cookies from "js-cookie";
import jstz from 'jstz';
import $ from 'jquery'

$(function () {
    if (!isIntegration()) {
        Cookies.set('timezone', jstz.determine().name(),
            {
                path: '/',
                secure: true,
                sameSite: "Lax"
            });
    }

    function isIntegration() {
        try {
            if (window.Cypress) {
                return window.parent !== window.top;
            }
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
});
