import $ from 'jquery'
import 'jquery-datetimepicker';

const htmlRoot = $('html');
const lang = htmlRoot.attr('lang')
const datePattern = htmlRoot.attr('data-jsDatePattern');
const timePattern = htmlRoot.attr('data-jsTimePattern');
$.datetimepicker.setLocale(lang);

const defaultConfig = {
    formatDate: datePattern,
    formatTime: timePattern,
    dayOfWeekStart: 1,
    todayButton: false,
    validateOnBlur: false,
    defaultTime: '12:00',
    step: 15
}

const initDatepicker = function () {
    $('input[data-ui=birthdate]').each(function () {
        const input = $(this);
        let config =
            {
                format: datePattern,
                maxDate: '0', // heute
                yearStart: new Date().getFullYear() - 100,
                yearEnd: new Date().getFullYear(),
                timepicker: false
            }
        $.extend(config, defaultConfig);
        input.datetimepicker(config);
    });

    $('input[data-ui=date]').each(function () {
        const input = $(this);
        let config =
            {
                format: datePattern,
                timepicker: false
            }
        $.extend(config, defaultConfig);
        input.datetimepicker(config);
    });

    $('input[data-ui=date-limit]').each(function () {
        const input = $(this);
        const yearStart = input.data('year-start');
        const yearEnd = input.data('year-end');
        let config =
            {
                format: datePattern,
                yearStart: yearStart,
                yearEnd: yearEnd,
                timepicker: false
            }
        $.extend(config, defaultConfig);
        input.datetimepicker(config);
    });

    $('input[data-ui=datetime]').each(function () {
        const input = $(this);
        let config =
            {
                format: datePattern + " " + timePattern,
                timepicker: true
            }
        $.extend(config, defaultConfig);
        input.datetimepicker(config);
    });
};

$(document).ready(initDatepicker);
