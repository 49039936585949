// wird von der integration überschrieben
window.heightChanged = function () {
}

window.navigate = function (url) {
    window.location = url;
}

// wird von der integration überschrieben
window.updateAnchorTarget = function (a) {
}

// wird von der integration überschrieben
window.fetchjson = function (url, options = {}) {
    return fetch(url, options).then(response => {
        return response.json()
    })
}

window.graphql = function (body) {
    body = JSON.stringify(body)
    return fetchjson(
        '/graphql',
        {
            body,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Content-Length': body.length,
            },
        }
    )
}