import $ from 'jquery'

if (isTouch()) {
    $('body').addClass("touch");
} else {
    $('body').addClass("notouch");
}


$("body").on("click", "a, tr.clickable, th.link", function (e) {
    // set class
    const obj = $(this);
    obj.addClass("clicked")
    setTimeout(function () {
        obj.removeClass("clicked");
    }, 500)
    // navigate if url
    const url = $(this).attr("data-url");
    if (url != null) {
        e.preventDefault();
        location.href = url;
        return false;
    }
});

function isTouch() {
    return !!(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);

}
