window.getPrivacyManagerId = function() {
  // PrivacyManager Ids
  const map = new Map()
  map.set('*.kicktipp.de', '1220834')
  map.set('*.kicktipp.co.uk', '1222601')
  map.set('*.kicktipp.fr', '1222603')
  map.set('*.kicktipp.com.br', '1222605')
  map.set('*.kicktipp.it', '1222606')
  map.set('*.kicktipp.at', '1222607')
  map.set('*.kicktipp.pl', '1222608')
  map.set('*.kicktipp.pt', '1222609')
  map.set('*.kicktipp.com', '1222610')
  map.set('*.kicktipp.es', '1222611')
  map.set('*.kicktipp.ch', '1222612')
  map.set('*.kicktipp.nl', '1222613')
  const hostname = window.location.host
  const index = hostname.indexOf('kicktipp')
  const property = '*.' + hostname.substring(index)
  return map.get(property)
}

window.cmpPrivacyManagerResponsive = function() {
  if (window.webkit) {
    window.webkit.messageHandlers.requestConsent.postMessage('')
  } else if (typeof Android !== 'undefined') {
    Android.requestConsent()
  } else {
    window.__tcfapi('displayConsentUi', 2, function() {
    })
  }
}

window.cmpPrivacyManagerStroeer = function() {
  if (viewConfig.werbung.werbefreiUser) {
    window.location = '/info/profil/werbefrei'
    return
  }
  if (window._sp_) {
    if (window._sp_.gdpr) {
      console.log('loadPrivacyManagerModal')
      window._sp_.gdpr.loadPrivacyManagerModal(getPrivacyManagerId())
    } else {
      // Ansonsten wurde das CMP noch gar nicht aufgerufen, also machen wir es:
      console.log('gdpr nicht vorhanden, daher executeMessaging')
      window._sp_.executeMessaging()
    }
  }
}

window.cmpPrivacyManager = function() {
  if (viewConfig.werbung.vermarkter === 'responsive') {
    cmpPrivacyManagerResponsive()
  } else {
    cmpPrivacyManagerStroeer()
  }
}

window.cmpClearConsent = function() {
  if (viewConfig.werbung.vermarkter === 'responsive') {
    return
  }
  console.log('cmpClearConsent')
  if (window._sp_) {
    if (window._sp_.gdpr) {
      window._sp_.gdpr.deleteConsentCookies()
    } else {
      console.log('Kein Consent vorhanden, der gelöscht werden könnte')
    }
  }
}

window.attCheckConsentCallback = function() {
  console.log('attCheckConsentCallback')
}

window.attCheckConsent = function() {
  if (window.webkit) {
    // ab build >= 5400
    if (window.webkit.messageHandlers.attCheckConsent) {
      window.webkit.messageHandlers.attCheckConsent.postMessage('')
    }
  }
}