import $ from 'jquery'

$.fn.gesamtuebersicht = function () {
    const ranking = $('#ranking');
    const wrapper = $('body');
    let minSpieltagIndex;
    let maxSpieltagIndex;
    let firstSpieltagIndex;
    let lastSpieltagIndex;
    let currentSpieltagIndex;
    const spieltagWidth = [];
    const summaryColumnsWidth = {};
    const summaryColumnClasses = ['bonus', 'siege', 'auswahlSiege', 'auswahlPunkte', 'punkte'];
    let widthGap;
    let width = $(window).width();

    function resizeWindow() {
        let newWidth = $(window).width();
        if (width !== newWidth) {
            ranking.find('.spieltag').show();
            $.each(summaryColumnClasses, function (index, value) {
                ranking.find('.' + value).show();
            });
            resizeRanking();
            width = newWidth;
        }
    }

    function resizeRanking() {
        initTabledata();
        resizeMitgliedName();
        hideColumns();
    }

    function resizeMitgliedName() {
        widthGap = $(ranking).getPixelNotInViewport(wrapper);
        if ($(window).width() < 1024) {
            if (widthGap === 0) {
                ranking.find('.mg_name').removeClass('small');
                ranking.find('.mg_class').removeClass('small');
                ranking.find('.mg_name').addClass('big');
                ranking.find('.mg_class').addClass('big');
                widthGap = $(ranking).getPixelNotInViewport(wrapper);
            }
            if (widthGap > 0) {
                ranking.find('.mg_name').removeClass('big');
                ranking.find('.mg_class').removeClass('big');
                widthGap = $(ranking).getPixelNotInViewport(wrapper);
            }
            if (widthGap > 0) {
                ranking.find('.mg_name').addClass('small');
                ranking.find('.mg_class').addClass('small');
                widthGap = $(ranking).getPixelNotInViewport(wrapper);
            }
        }
    }

    function initTabledata() {
        ranking.find('th.spieltag').each(function () {
            spieltagWidth[$(this).data('index')] = $(this).get(0).getBoundingClientRect().right - $(this).get(0).getBoundingClientRect().left;
        });
        $.each(summaryColumnClasses, function (index, value) {
            const summaryColumn = ranking.find('th.' + value);
            if (summaryColumn.length) {
                summaryColumnsWidth[value] = summaryColumn.get(0).getBoundingClientRect().right - summaryColumn.get(0).getBoundingClientRect().left;
            }
        });
        widthGap = $(ranking).getPixelNotInViewport(wrapper);
        firstSpieltagIndex = minSpieltagIndex;
        lastSpieltagIndex = maxSpieltagIndex;
    }

    function hideColumns() {
        $.each(getColumnClassesToHide(), function (index, value) {
            hideColumn(value);
        })
        updatePrevNextLinks();
    }

    function getColumnClassesToHide() {
        let hiddenWidth = 0;
        const columnClasses = [];
        for (let index = lastSpieltagIndex; index > currentSpieltagIndex; index--) {
            if (hiddenWidth < widthGap) {
                columnClasses.push('spieltag' + index);
                lastSpieltagIndex--;
                hiddenWidth += spieltagWidth[index];
            } else {
                return columnClasses;
            }
        }
        for (let index = firstSpieltagIndex; index < currentSpieltagIndex; index++) {
            if (hiddenWidth < widthGap) {
                columnClasses.push('spieltag' + index);
                firstSpieltagIndex++;
                hiddenWidth += spieltagWidth[index];
            } else {
                return columnClasses;
            }
        }
        $.each(summaryColumnClasses, function (index, value) {
            if (hiddenWidth < widthGap) {
                let summaryColumnWitdh = summaryColumnsWidth[value];
                if (summaryColumnWitdh > 0) {
                    columnClasses.push(value);
                    hiddenWidth += summaryColumnWitdh;
                }
            } else {
                return columnClasses;
            }
        });
        return columnClasses;
    }

    function hideColumn(columnClass) {
        ranking.find('.' + columnClass).hide();
    }

    function showColumn(columnClass) {
        ranking.find('.' + columnClass).show();
    }

    function updatePrevNextLinks() {
        const firstVisible = firstSpieltagIndex === minSpieltagIndex;
        const lastVisible = lastSpieltagIndex === maxSpieltagIndex;
        const rprev = $('.rprev');
        const rnext = $('.rnext');
        if (firstVisible && lastVisible) {
            rprev.hide();
            rnext.hide();
        } else {
            rprev.show();
            rnext.show();
            if (firstVisible) {
                rprev.addClass('disabled');
            } else {
                rprev.removeClass('disabled');
            }
            if (lastVisible) {
                rnext.addClass('disabled');
            } else {
                rnext.removeClass('disabled');
            }
        }
    }

    function handlePrev() {
        let icon = $('.rprev');
        icon.addClass("clicked");
        if (firstSpieltagIndex > minSpieltagIndex) {
            firstSpieltagIndex--;
            showColumn('spieltag' + firstSpieltagIndex);
            do {
                hideColumn('spieltag' + lastSpieltagIndex);
                lastSpieltagIndex--;
            }
            while (ranking.getPixelNotInViewport(wrapper) > 0 && lastSpieltagIndex > minSpieltagIndex);
            updatePrevNextLinks();
        }
        setTimeout(() => icon.removeClass("clicked"), 180);
    }

    function handleNext() {
        let icon = $('.rnext');
        icon.addClass("clicked");
        if (lastSpieltagIndex < maxSpieltagIndex) {
            lastSpieltagIndex++;
            showColumn('spieltag' + lastSpieltagIndex);
            do {
                hideColumn('spieltag' + firstSpieltagIndex);
                firstSpieltagIndex++;
            }
            while (ranking.getPixelNotInViewport(wrapper) > 0 && firstSpieltagIndex < maxSpieltagIndex);
            updatePrevNextLinks();
        }
        setTimeout(() => icon.removeClass("clicked"), 180);
    }

    function bindEvents() {
        $('.rprev').click(handlePrev);
        $('.rnext').click(handleNext);
        window.bindArrowKeys(handlePrev, handleNext)
        window.bindSwipe(ranking[0], handleNext, handlePrev);
        $(window).resize(resizeWindow);
    }

    function getCurrentSpieltagIndex() {
        let currentIndex = minSpieltagIndex;
        $.each($(ranking).find('tr.teilnehmer').first().find('td.spieltag'), function (index, spieltagTD) {
            if ($(spieltagTD).html().length === 0) {
                return currentIndex;
            }
            currentIndex++;
        });
        return currentIndex - 1;
    }

    function init() {
        let spieltageHeader = $(ranking).find('th.spieltag');
        minSpieltagIndex = spieltageHeader.first().data('index');
        maxSpieltagIndex = spieltageHeader.last().data('index');
        currentSpieltagIndex = getCurrentSpieltagIndex();
    }

    return this.each(function () {
        init();
        bindEvents();
        resizeRanking();
    });
}
