import $ from 'jquery'

function menuToggle(button, menu, event) {
    if (menu.hasClass("toggled")) {
        hideAll(event);
    } else {
        menuShow(button, menu, event);
    }
}

function menuShow(button, menu, event) {
    if (menu.css('display') === 'block') {
        return;
    }
    if (event != null) {
        event.preventDefault();
    }

    window.scrollTo(0, 0);

    hideAll(event);
    button.addClass("toggled");
    menu.addClass("toggled");
    overlay.addClass("toggled");
    let topbar = $("#topbar");
    let topbarBottom = topbar.offset().top + topbar.height();
    let screenHeight = $(document).height();
    let wrapperHeight = screenHeight - topbarBottom;
    wrapper.css({
        height: wrapperHeight
    });
}

function hideAll(event) {
    navigationMenu.scrollTop(0);
    tippglockeMenu.scrollTop(0);
    seitenmenu.scrollTop(0);
    toggle.removeClass("toggled");
    navigationMenu.removeClass("toggled");
    tippglockeIcon.removeClass("toggled");
    tippglockeMenu.removeClass("toggled");
    seitenmenuIcon.removeClass("toggled");
    dropdowntitle.removeClass("toggled");
    seitenmenu.removeClass("toggled");
    $("#seitenmenu .dropdownitems").hide();
    overlay.removeClass("toggled");
    wrapper.css(
        {
            height: 'auto',
        });
    if (event != null) {
        event.preventDefault();
    }
}

function doOnOrientationChange() {
    hideAll(null);
}

function dropdownToggle(dropdowntitle) {
    $(dropdowntitle).toggleClass('toggled')
    let dropdownitems = $(dropdowntitle).parent().children('.dropdownitems');
    dropdownitems.toggle();
    $(dropdowntitle).removeClass("clicked");
}

const wrapper = $("#wrapper");
const toggle = $("#toggle");
const navigationMenu = $("#navigation");
const tippglockeIcon = $("#tippglocke-icon .navtoggle");
const tippglockeMenu = $("#tippglocke-menu");
const seitenmenuIcon = $("#seitenmenu-icon .navtoggle");
const seitenmenu = $("#seitenmenu");
const dropdowntitle = $("#seitenmenu .dropdowntitle");
const overlay = $("#overlay");

window.addEventListener('orientationchange', doOnOrientationChange);

toggle.on("click", function (event) {
    menuToggle(toggle, navigationMenu, event);
});
tippglockeIcon.on("click", function (event) {
    menuToggle(tippglockeIcon, tippglockeMenu, event)
});
seitenmenuIcon.on("click", function (event) {
    menuToggle(seitenmenuIcon, seitenmenu, event)
});

dropdowntitle.on("click", function () {
    dropdownToggle(this);
});

overlay.on("click", function (event) {
    hideAll(event);
});
