import PullToRefresh from 'pulltorefreshjs'
if (window.mobileDeviceTokenDTO.appRequest) {
    PullToRefresh.init({
        distThreshold: 80,
        distMax: 100,
        distReload: 50,
        distIgnore: 50,
        mainElement: '#wrapper',
        triggerElement: 'html',
        ptrElement: '.ptr',
        classPrefix: 'pulltorefresh-',
        cssProp: 'min-height',
        getMarkup() {
            return `
<div class="__PREFIX__box">
  <div class="__PREFIX__content">
    <div class="__PREFIX__icon"></div>
  </div>
</div>
`;
        },
        // see 89-pulltoRefresh
        getStyles() {
            return ' ';
        },
        iconArrow: '<span class="kicktipp-icon-cw pulltorefresh"></span>',
        iconRefreshing: '<span class="kicktipp-icon-cw pulltorefresh pulltorefresh-refreshing"></span>',
        refreshTimeout: 400
    });
}
