import $ from 'jquery'

bindToolbarClicks();

function bindToolbarClicks() {
    $('div.favorit-on a, div.favorit-off a').click(handleFavoritClickEvent);
    $(document).click(removeApiMessages);
}

function handleFavoritClickEvent(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const favoritLink = $(this);
    const addFavorit = favoritLink.parent().hasClass('favorit-off');
    const teilnehmerId = favoritLink.attr("data-teilnehmerId");
    const teilnehmerType = favoritLink.attr("data-teilnehmerType");
    const tipprundeKurzname = favoritLink.attr("data-kurzname");
    if (addFavorit) {
        favoritHinzufuegen(tipprundeKurzname, teilnehmerId, teilnehmerType);

    } else {
        favoritEntfernen(tipprundeKurzname, teilnehmerId, teilnehmerType);
    }
    return false;
}

async function favoritHinzufuegen(tipprundeKurzname, teilnehmerId, teilnehmerType) {
    const query = {
        query: `mutation { favoritHinzufuegen(tipprundeKurzname: "${tipprundeKurzname}", teilnehmerId: ${teilnehmerId}, teilnehmerType: "${teilnehmerType}")}`
    }
    graphql(query).then(json => {
        if (json.errors) {
            handleFavoritMessages("errors", json.errors);
        } else {
            switchFavoritTool('off', 'on')
        }
    });

}

async function favoritEntfernen(tipprundeKurzname, teilnehmerId, teilnehmerType) {
    const query = {
        query: `mutation { favoritEntfernen(tipprundeKurzname: "${tipprundeKurzname}", teilnehmerId: ${teilnehmerId}, teilnehmerType: "${teilnehmerType}")}`
    }
    graphql(query).then(json => {
        if (json.errors) {
            handleFavoritMessages("errors", json.errors);
        } else {
            switchFavoritTool('on', 'off')
        }
    })
}

function removeApiMessages() {
    $('div.toolmessage').remove();
    return true;
}

function handleFavoritMessages(messageType, errors) {
    $('div.toolmessage').remove();
    const div = document.createElement("div");
    div.setAttribute("class", "toolmessage messagebox dismiss " + messageType);
    for (let index = 0; index < errors.length; index++) {
        const p = $(document.createElement("p"));
        const text = document.createTextNode(errors[index].message);
        $(p).append(text);
        $(div).append(p);
    }
    // sont geht der outline um die ganze box rum.
    $('div.kicktipp-tool a').append(div);
    // favoritLink.append(div);
    const box = $("div.toolmessage");
    box.click(function () {
        $(this).remove();
        return false;
    });
}

function switchFavoritTool(switchFrom, switchTo) {
    const favoritLink = $('div.kicktipp-tool a');
    favoritLink.parent().removeClass('favorit-' + switchFrom);
    favoritLink.parent().addClass('favorit-' + switchTo);
    favoritLink.children().first().removeClass('kicktipp-icon-favorit-' + switchFrom);
    favoritLink.children().first().addClass('kicktipp-icon-favorit-' + switchTo);
}
