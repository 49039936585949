
window.appResume = function () {
    window.onAppResume.trigger();
}

window.onAppResume = {
    trigger() {
        if (typeof this.next !== 'undefined' && typeof this.next === 'function') {
            this.next();
        }
        this.reset()
    },
    next: () => {},
    reset() {
        this.next = () => {}
    },
}
