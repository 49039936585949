import $ from 'jquery'

$(function () {
    const needsFix = (/Android 4/i).test(navigator.userAgent);
    if (needsFix) {
        $("input[inputmode='numeric']").each(
            function () {
                this.type = "tel";
            }
        );
    }
});
